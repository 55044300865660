//import $ from 'jquery';
import axios from 'axios';
import $ from 'jquery';

var common = {
    checkAdminSession: function(appConfig){
        var userToken = localStorage.getItem('adminToken');
        var postData = new FormData();
        postData.append('token',userToken);

        return axios.post(appConfig.backendUrl + 'checkAdminSession.php',postData);

    },
    init: function(){
        var _this = this;
      
        $('.sadrzaj .animator').animate({ marginLeft: "0px", opacity: "1" }, { duration: 500, complete: function () { } });
        $("#odjava").unbind("click").click(function(e){
            e.preventDefault();
            localStorage.removeItem('adminToken');
            document.location.reload();
        });
        $("#pocetna").unbind("click").click(function(e){
            e.preventDefault();
            document.location.href = _this.getRelativeRoot();
        });
        $("#stavke").unbind("click").click(function(e){
            e.preventDefault();
            document.location.href = _this.getRelativeRoot() + "stavke.html";
        });
        $("#clanovi").unbind("click").click(function(e){
            e.preventDefault();
            document.location.href = _this.getRelativeRoot() + "clanovi.html";
        });
        $("#grupe").unbind("click").click(function(e){
            e.preventDefault();
            document.location.href = _this.getRelativeRoot() + "grupe.html";
        });

        setTimeout(function(){  $('[vp-cloak]').show();}, 100);
    },
    getRelativeRoot: function(){
            var currentPage = document.location.href;
            var pattern = /\//g;
            var slashes = currentPage.match(pattern);
            var root = "";
            for(var i=0, iLen = slashes.length - 3; i<= iLen; i++){
                root += "../";
            }
            //console.log("root", root);
            return root;

    },
    getParam: function($route, what){
        return $route.params[what] ? $route.params[what] : $route.query[what];
    },
    decodeHtml: function(html) {
        var txt = document.createElement("textarea");
        txt.innerHTML = html;
        return txt.value;
    }
}

common.init();

export default common;