<template>
    <div id="app">
        <form>
            <input type="hidden" v-model="ID" name="ID" value="">
            <div class="form-horizontal">
            <div v-show="!pass" style="color:red;text-align:center">{{msg}}</div>
             <div v-show="pass && msg != ''" style="color:#008fb8;text-align:center">{{msg}}</div>
            <div style="width:46%;display: inline-block; padding: 20px; vertical-align: top;padding-top: 0">
                <div class="form-group">
                    <label class="control-label col-md-2" for="Ime">Име и Презиме*</label>
                    <div class="col-md-10">
                        <input class="form-control text-box single-line" data-val="true" data-val-required="Унесите име и презиме!" id="Ime" name="ImePrezime" v-model="ImePrezime" type="text" value="" />
                        <span class="field-validation-valid text-danger" data-valmsg-for="Ime" data-valmsg-replace="true"></span>
                    </div>
                </div>
                <div class="form-group">
                    <label class="control-label col-md-2" for="Email">Емаил*</label>
                    <div class="col-md-10">
                        <input class="form-control text-box single-line" data-val="true" data-val-regex="Емаил није валидан!" data-val-regex-pattern="^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$" data-val-required="Унесите Е-маил!" id="Email" name="Email" v-model="Email" type="text" value="" />
                        <span class="field-validation-valid text-danger" data-valmsg-for="Email" data-valmsg-replace="true"></span>
                    </div>
                </div>
                  <div class="form-group">
                    <label class="control-label col-md-2" for="Grupa">Е-маил група</label>
                    <div class="col-md-10">
                        <select name="Grupa" v-model="Grupa">
                            <option v-for="grupa in Grupe" :key="grupa.ID" :value="grupa.ID">{{grupa.Naziv}}</option>
                        </select>
                    </div>
                </div>
                <div class="form-group" align="right">
                    <div class="col-md-offset-2 col-md-10">
                        <button type="submit" value="" @click.prevent="process" class="btn btn-default">Додај члана</button>
                    </div>
                </div>
            </div>
        </div>
        </form>
    </div>

</template>
<script>
import axios from 'axios';
import appConfig from '../js/configuration.js';
import common from '../js/common.js';

export default {
  name: 'App',
   data: function() {
        return {
        ID: 0,
        ImePrezime: '',
        Grupa: 0,
        msg: '',
        Email: '',
        Grupe: [],
        pass: true
      }
    },
    methods: {
        fetch: function(){
             var _this = this;
        // var userToken = localStorage.getItem('adminToken');
        //   if(userToken != null)
          {
           
             var postData = new FormData();
             //postData.append('token',userToken);
             postData.append('id',common.getParam(this.$route,"id"));
            axios.post(appConfig.backendUrl + 'adminClan.php',postData).then(function(response){
                  var k = response.data[0];
                   _this.ID = k.ID;
                   _this.ImePrezime= k.ImePrezime;
                    _this.Email= k.Email;
                    _this.Grupa = k.Grupa
                   _this.fetchGrupe();
              });
          }
        },
        fetchGrupe: function(){
             var _this = this;
            var userToken = localStorage.getItem('adminToken');
            if(userToken != null){
                    var postData = new FormData();
                    postData.append('token',userToken);
                    axios.post(appConfig.backendUrl + 'adminGrupe.php',postData).then(function(response){
                        _this.Grupe = response.data;
                    });
                }
        },
        process: function(){
            var _this = this;
             var postData = new FormData();
             postData.append('ID',this.ID);
            postData.append('ImePrezime',this.ImePrezime);
            postData.append('Email',this.Email);
            postData.append('Grupa',this.Grupa);
            axios.post(appConfig.backendUrl + 'adminClanInsertUpdate.php', postData).then(function(response){
                  
                     if(response.data.result === true){
                   _this.pass = true;
                   _this.msg = response.data.msg;
                    // _this.$emit('updateRegistracija', {"email" : _this.Email, "pwd" :_this.Lozinka} ); 
                }
                else{
                   _this.pass = false;
                   _this.msg = response.data.msg;
                }
               
            });
          //console.log('process');
        }
    },
    mounted: function(){
          this.fetch();
            //console.log('created moj profil');
    }
}
</script>
