var appConfig = {
    //backendUrl: 'http://localhost:8082/sld-elektronski-testovi/api/',
   backendUrl: 'https://services.btb4net.com/sld-mailer/api/',
   init: function(){

   }
}

appConfig.init();

export default appConfig;