<template>
  <div id="app">
    <div v-if="!submitted">
    <AdminLogovanje @updateNaslovna="submit" />
    </div>
    <div v-else>
    <h3>Е-маил група(секција, подружница...)</h3>
    <form>
        <input type="hidden" v-model="ID" name="ID" value="">
        <div class="form-horizontal">
            <div v-show="!pass" style="color:red;text-align:center">{{msg}}</div>
            <div v-show="pass && msg != ''" style="color:#008fb8;text-align:center">{{msg}}</div>
        </div>
        <div class="form-group">
            <label class="control-label col-md-2" for="Naziv">Назив*</label>
            <div class="col-md-10">
                <textarea cols="20" htmlAttributes="{ class = form-control }" id="Naziv" name="Naziv" v-model="Naziv"  rows="2"></textarea>
            </div>
        </div>
         <div class="form-group">
              <label class="control-label col-md-2" for="replyToMail">Е-маил за одговоре</label>
              <div class="col-md-10">
                  <input class="form-control text-box single-line" data-val="true" data-val-regex="Емаил није валидан!" style="width:500px;" data-val-regex-pattern="^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$" data-val-required="Унесите Е-маил!" id="replyToMail" name="replyToMail" v-model="replyToMail" type="text" value="" />
                  <span class="field-validation-valid text-danger" data-valmsg-for="Email" data-valmsg-replace="true"></span>
              </div>
          </div>
         <div class="form-group">
              <label class="control-label col-md-2" for="replyToMail">Url групе</label>
              <div class="col-md-10">
                  <input class="form-control text-box single-line" data-val="true" style="width:500px;"  id="replyToMail" name="Url" v-model="Url" type="text" value="" />
                  <span class="field-validation-valid text-danger" data-valmsg-for="Url" data-valmsg-replace="true"></span>
              </div>
          </div>
          <div class="form-group">
            <label class="control-label col-md-2" for="Logo">Logo</label>
            <div class="col-md-10">
                <textarea cols="20" htmlAttributes="{ class = form-control }" id="Logo" name="Logo" v-model="Logo"  rows="2"></textarea>
                <div>Напомена: Као лого користити <strong>code/base64</strong> садржај уместо адресе слике. Да се од слике добије <strong>code/base64</strong> садржај je овај <a href="https://www.base64-image.de/" target="_blank">ЛИНК</a>.</div>
            </div>
        </div>
        <div class="form-group" >
            <label class="control-label col-md-2" for="BojaZaglavlja">Боја заглавља</label>
            <div class="col-md-10">
                <!-- <input class="form-control text-box single-line" data-val="true" data-val-required="Унесите боју позадине!" id="Boja" name="Boja" v-model="Boja" type="text" value="" /> -->
                <!-- <chrome :value="colors" @input="onColorChange"></chrome> -->
               <sketch v-model="colors"></sketch>
            </div>
        </div>
         <div class="form-group" >
            <label class="control-label col-md-2" for="BojaZaglavlja">Боја слова заглавља</label>
            <div class="col-md-10">
                <!-- <input class="form-control text-box single-line" data-val="true" data-val-required="Унесите боју позадине!" id="Boja" name="Boja" v-model="Boja" type="text" value="" /> -->
                <!-- <chrome :value="colors" @input="onColorChange"></chrome> -->
               <sketch v-model="fontColor"></sketch>
            </div>
        </div>
        <div class="form-group" align="right">
            <div class="col-md-offset-2 col-md-10">
                <button type="submit" value="" @click.prevent="submit" class="btn btn-default" v-if="ID == 0">Додај</button>
                <button type="submit" value="" @click.prevent="submit" class="btn btn-default" v-else>Измени</button>
            </div>
        </div>
    </form>
    <div>
        <a href="../grupe.html">Назад</a>
    </div>
   </div>
  </div>
</template>

<script>
import AdminLogovanje from '../../components/AdminLogovanje.vue'
import axios from 'axios';
import $ from 'jquery';
import appConfig from '../../js/configuration.js'
import common from '../../js/common.js'
//require('../../js/configuration.js');
//require('../../js/common.js');
//require('@/js/jquery.magnific-popup.js')
import { Sketch } from 'vue-color'

var colors = JSON.parse('{"hsl":{"h":0,"s":0,"l":0,"a":0},"hex":"#000000","hex8":"#00000000","rgba":{"r":0,"g":0,"b":0,"a":0},"hsv":{"h":0,"s":0,"v":0,"a":0},"oldHue":0,"source":"hex","a":0}');
var fontColor = JSON.parse('{"hsl":{"h":0,"s":0,"l":0.2901960784313726,"a":1},"hex":"#4A4A4A","hex8":"#4A4A4AFF","rgba":{"r":74,"g":74,"b":74,"a":1},"hsv":{"h":0,"s":0,"v":0.2901960784313726,"a":1},"oldHue":0,"source":"hex","a":1}');

export default {
  name: 'AdminGrupa',
  data: function(){
    return {
        ID: 0,
        Naziv: '',
        BojaZaglavlja: '',
        BojaSlovaZaglavlja: '',
        replyToMail: '',
        Url: '',
        Logo: '',
        msg: '',
      submitted: false,
      sekcija: [],
      pass: true,
      colors,
      fontColor
    }
  },
  components: {
    AdminLogovanje,
    Sketch
  },  
   methods: {
       init: function(){
          var _this = this;
          var id = common.getParam(this.$route,"id");
          common.checkAdminSession(appConfig).then(function(response){
              _this.submitted = response.data.result;
              if(!_this.submitted) {
                  $('#admin_logovanje').show();
              }
              else{
                    _this.fetch(id);
              }
          });
          
         // return this.submitted;
      },
      fetch: function(id) {
            
        if(id > 0) {
            var _this = this;
            var userToken = localStorage.getItem('adminToken');
          if(userToken != null){
                var postData = new FormData();
                postData.append('token',userToken);
                postData.append('id',id);
                axios.post(appConfig.backendUrl + 'adminGrupa.php',postData).then(function(response){
                    var k = response.data[0];
                        _this.ID = k.ID;
                        _this.Naziv = k.Naziv;
                        _this.replyToMail = k.replyToMail;
                        _this.BojaZaglavlja= k.BojaZaglavlja;
                         _this.BojaSlovaZaglavlja= k.BojaSlovaZaglavlja;
                        _this.Logo= k.Logo;
                         _this.Url= k.Url;
                        if(k.BojaZaglavlja != '')
                          _this.colors = JSON.parse(k.BojaZaglavlja);
                        if(k.BojaSlovaZaglavlja != '')
                          _this.fontColor = JSON.parse(k.BojaSlovaZaglavlja);
    
                      
                });
            }

        }
      },
        submit: function(){
          var _this = this;
          common.checkAdminSession(appConfig).then(function(response){
              _this.submitted = response.data.result;
              if(!_this.submitted) {
                  $('#admin_logovanje').show();
              }
              else{
                    _this.Boja =JSON.stringify(_this.colors); 
                    var postData = new FormData();
                    postData.append('ID',_this.ID);
                    postData.append('Naziv', _this.Naziv);
                    postData.append('BojaZaglavlja', JSON.stringify(_this.colors));
                     postData.append('BojaSlovaZaglavlja', JSON.stringify(_this.fontColor));
                    postData.append('replyToMail',_this.replyToMail);
                     postData.append('Logo',_this.Logo);
                     postData.append('Url',_this.Url);
                    axios.post(appConfig.backendUrl + 'adminGrupaInsertUpdate.php', postData).then(function(response){
                       
                        if(response.data.result === true){
                            _this.pass = true;
                        //
                            if(_this.ID == 0){
                                document.location.href = "../grupe.html"; 
                            }
                            else {
                                _this.msg = response.data.msg;
                            }

                        }
                        else{
                        _this.pass = false;
                        _this.msg = response.data.msg;
                        }
                    
                    });
                   //console.log('submit Registracije');
              }
          });
        },
        submitRegistracije: function(){
         
          //console.log('submit Registracije');
        },
         onColorChange: function(val) {
            this.colors = val;
            this.BojaZaglavlja = JSON.stringify( this.colors);
            }
    },

    mounted: function(){
      //console.log("created 1");
     //  //console.log('Params: ', );
      this.init();
      //console.log(this.submitted);
    }
}
</script>

<style>
#app {
  /* font-family: Avenir, Helvetica, Arial, sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
  /* color: #2c3e50;
  margin-top: 60px; */
}

</style>
